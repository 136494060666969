* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}
